import { ConfigKey, ConfigState, ICollection, INFT, RootState } from './types';

export const arkDefaultValues: RootState = {
	ark: {
		showLoginRegisterDialog: false,
		login: {
			isLogged: false,
			token: '',
		},
		user: {},
		userInfo: {},
		profile: {
			success: false,
			error: false,
			data: {},
			errorMessage: '',
		},
		category: [],
		inRequest: [],
		collectionInfo: {
			name: '',
			description: '',
			category: '',
			icon: '',
			cover: '',
			website: '',
			instagram: '',
			tiktok: '',
			twitter: '',
			userID: '',
			categoryID: 0,
		},
		createCollectionError: false,
		updateCollectionError: false,
		collectionDetailError: false,
		updateCollectionSuccess: false,
		createCollectionSuccess: false,
		application: '',
		categories: [],
		collectionByCategory: [],
		collectionByCategoryCount: 0,
		collectionProfile: {} as ICollection,
		nft: {
			creator: '0',
		} as INFT,
		collectionByUserId: [],
		createNFT: {
			data: {},
			error: null,
		},
		createApplicationSuccess: false,
		createApplicationError: false,
		landingPage: {
			loading: false,
			success: false,
			error: '',
			collections: [],
			newCollections: [],
			orders: [],
		},
		nftCollection: {
			data: [],
			count: 0,
		},
		collectionOrders: {
			result: [],
			cursor: '',
			remaining: 0,
		},
	},
	user: {
		categories: [],
		collections: [],
		collectionsCount: 0,
		nftCollectionsListForDropdown: {
			list: [],
			totalCount: 0,
		},
		nftCollections: {
			result: [],
			cursor: '',
			remaining: 0,
		},
		wishlist: {
			items: [],
			totalCount: 0,
		},
	},
	marketPlace: {
		marketPlaceOrders: {
			result: [],
			cursor: '',
			remaining: 0,
		},
		marketPlaceOrdersCount: 0,
		marketPlaceCollectionList: {
			assets: [],
			cursor: '',
		},
	},
	history: {
		ordersHistory: { result: [], cursor: '', remaining: 0 },
		depositsHistory: { result: [], cursor: '', remaining: 0 },
		withdrawalsHistory: { result: [], cursor: '', remaining: 0 },
		transfersOutHistory: { result: [], cursor: '', remaining: 0 },
		transfersInHistory: { result: [], cursor: '', remaining: 0 },
	},
	wallet: {
		walletBalance: '',
		walletBalanceIsLoading: false,
		walletLoadSuccess: false,
		walletLoadError: false,
		depositProcessing: false,
		depositSuccess: false,
		depositError: false,
		balance: 0.0,
		preparing: 0.0,
		preparingETH: 0.0,
		withdrawable: 0.0,
		withdrawRequestProcessing: false,
		withdrawRequestSuccess: false,
		withdrawRequestError: false,
		withdrawCompleteProcessing: false,
		withdrawCompleteSuccess: false,
		withdrawCompleteError: false,
		walletBalanceETH: 0.0,
		withdrawableETH: 0.0,
	},
	collection: {
		createNotification: {
			title: '',
			message: '',
			icon: null,
		},
		createNotificationError: '',
		createNotificationLoading: false,
		createNotificationSuccess: false,
	},
};

export const configDefaultValues: ConfigState = {
	siteConfig: [
		{
			ADMIN_ONLY: 0,
			NAME: ConfigKey.SITE_DEFAULT_HEADER,
			VALUE: 'https://ipfs.thearknft.io/ipfs/bafkreigwoxjw3ulf2wmb6i6e7nyco66fpm47jpodojoqj3tp6orvrod4nu',
		},
		{
			ADMIN_ONLY: 0,
			NAME: ConfigKey.SITE_FOOTER_DARK,
			VALUE: 'https://ipfs.thearknft.io/ipfs/bafkreifwwkzfil3l3yljoiucqs5fkjkdrlqmdtyphldk6rbqfx4r22t46e',
		},
		{
			ADMIN_ONLY: 0,
			NAME: ConfigKey.SITE_LANDING_BANNER,
			VALUE: 'https://ipfs.thearknft.io/ipfs/bafybeiaygkxoypwauwmkeae3jhddkzoovv27whzttn7s4bmtwalthajl7u',
		},
		{
			ADMIN_ONLY: 0,
			NAME: ConfigKey.SITE_LOGO_DARK,
			VALUE: 'https://ipfs.thearknft.io/ipfs/bafkreicmq7c42jnihpviwtbuiacrlrbyezu4mztauzntwzkol5ybdqjj5a',
		},
		{
			ADMIN_ONLY: 0,
			NAME: ConfigKey.SITE_NAME,
			VALUE: 'The ARK',
		},
		{
			ADMIN_ONLY: 0,
			NAME: ConfigKey.SITE_TITLE,
			VALUE: 'Welcome to The Ark',
		},
	],
};
