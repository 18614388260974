import '../styles/globals.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '../src/theme/styles.css';
import NextApp, { AppProps } from 'next/app';
import Layout from '../src/components/Layout';
import { getLoginSession } from '@theark/react-common';
import NextNProgress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as gtag from '@theark/react-common';
import Script from 'next/script';
import Head from 'next/head';

interface ArkProps extends AppProps {
	userToken?: string;
}

const App = ({ Component, pageProps, userToken }: ArkProps) => {
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			gtag.pageview(url);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		router.events.on('hashChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
			router.events.off('hashChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no" />
			</Head>
			<Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} />
			<Script
				id="gtag-init"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${gtag.GA_TRACKING_ID}', {
						page_path: window.location.pathname,
						});
					`,
				}}
			/>
			<NextNProgress height={6} color={`linear-gradient(0deg, rgba(250, 232, 70, 1) 0%, rgba(71, 139, 162, 1) 100%)`} />
			<Layout userToken={userToken}>
				<Component {...pageProps} />
			</Layout>
		</>
	);
};

App.getInitialProps = async (context: any) => {
	const {
		ctx: { req },
	} = context;
	const appProps = await NextApp.getInitialProps(context);
	const token: string = req?.cookies?.token || '';
	const userToken: string = getLoginSession(token);
	return { ...appProps, userToken };
};

export default App;
