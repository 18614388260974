import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { BorderButtonProps } from '../../types';

export const BorderButton: React.FC<BorderButtonProps> = ({
	disabled = false,
	children,
	isLarge = false,
	callback,
	isNonBorderButton = false,
	mt,
	...rest
}) => {
	return !isNonBorderButton ? (
		<Box
			onClick={disabled ? () => {} : callback}
			p={0.5}
			borderRadius="5"
			as="div"
			bgGradient="linear-gradient(29deg, #bcc866 0%, #689c90)"
			mt={mt}
		>
			<Button disabled={disabled} bg="#505050" size={isLarge ? 'lg' : 'sm'} width="100%" {...rest}>
				{children}
			</Button>
		</Box>
	) : (
		<Button
			variant="gradient-primary"
			onClick={disabled ? () => {} : callback}
			isDisabled={disabled}
			size={isLarge ? 'lg' : 'sm'}
			width="100%"
			mt={mt}
			{...rest}
		>
			{children}
		</Button>
	);
};
