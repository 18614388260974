import React from 'react';
import { useRouter } from 'next/router';
import {
	Avatar,
	AvatarGroup,
	Badge,
	Box,
	Button,
	Center,
	Image,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { IoIosCreate, IoIosLogOut, IoIosPerson, IoIosSettings, IoMdHeartEmpty, IoMdWallet } from 'react-icons/io';
import { AiOutlineMedicineBox } from 'react-icons/ai';
import { BiCollection } from 'react-icons/bi';
import { useArkContext } from '../../contexts';
import { selectUserInfo } from '../../store/selectors';
import { logoutRequest } from '../../store/actions';
import { IPFS_IMAGE } from '@theark/react-common';

const fallBackImage = `https://source.unsplash.com/random/300×200/?art`;

const ProfileOptions: React.FC<any> = () => {
	const {
		state: { ark: arkState },
		dispatch,
	} = useArkContext();
	const router = useRouter();
	const userInfo = selectUserInfo(arkState);
	const navigateHandler = (e: any, action: string) => {
		router.push(action);
	};

	const logoutHandler = () => {
		logoutRequest(arkState?.user?.accessToken, dispatch);
		setTimeout(() => {
			router.push('/');
		});
	};

	return (
		<Menu>
			<MenuButton as={Button}>
				<AvatarGroup cursor="pointer">
					<Avatar size="sm" bgGradient="linear(to-r, rgba(250, 232, 70, 1), rgba(71, 139, 162, 1))" />
				</AvatarGroup>
			</MenuButton>
			<MenuList className="profileSetting" minWidth={190} bg={'#202020'} fontSize={14}>
				<MenuGroup>
					<Box textAlign={'center'} p={3} height={160}>
						<Image
							alt="avatar Image"
							m={'auto'}
							borderRadius={'full'}
							boxSize={100}
							src={userInfo?.avatar ? `${IPFS_IMAGE}${userInfo?.avatar}` : fallBackImage}
						/>
						<Text my={4}>
							{arkState.user?.userInfo?.USERNAME ||
								arkState.user?.userInfo?.ETH_ADDRESS?.slice(0, 7) +
									'...' +
									arkState.user?.userInfo?.ETH_ADDRESS?.slice(15, 20)}
						</Text>
					</Box>
					{arkState.user?.userInfo?.USER_TYPE === 1 && (
						<Center>
							<Box as="div">
								<Badge p={0.5} variant="solid" colorScheme="green">
									Creator
								</Badge>
							</Box>
						</Center>
					)}
				</MenuGroup>
				<MenuDivider />
				<MenuGroup>
					<MenuItem
						onClick={(e: any) => navigateHandler(e, '/profile')}
						icon={<IoIosPerson size={18} />}
						_hover={{
							bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
						}}
					>
						Profile
					</MenuItem>
					<MenuItem
						onClick={(e: any) => navigateHandler(e, '/account')}
						icon={<IoIosSettings size={18} />}
						_hover={{
							bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
						}}
					>
						Account
					</MenuItem>
					<MenuItem
						onClick={(e: any) => navigateHandler(e, '/wallet')}
						icon={<IoMdWallet size={18} />}
						_hover={{
							bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
						}}
					>
						Wallet
					</MenuItem>
					{arkState.userInfo?.USER_TYPE == 1 && (
						<MenuItem
							onClick={(e: any) => navigateHandler(e, '/collection/my')}
							icon={<BiCollection size={18} />}
							_hover={{
								bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
							}}
						>
							My Collection
						</MenuItem>
					)}
					<MenuItem
						onClick={(e: any) => navigateHandler(e, '/user/nft')}
						icon={<AiOutlineMedicineBox size={18} />}
						_hover={{
							bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
						}}
					>
						My NFT&lsquo;s
					</MenuItem>
					{arkState.user?.userInfo?.APPLICATION_FOR_CREATOR == 0 && (
						<MenuItem
							onClick={(e: any) => navigateHandler(e, '/application/create')}
							icon={<IoIosCreate size={18} />}
							_hover={{
								bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
							}}
						>
							Become a Creator
						</MenuItem>
					)}
					<MenuItem
						onClick={(e: any) => navigateHandler(e, '/user/favorite')}
						icon={<IoMdHeartEmpty size={18} />}
						_hover={{
							bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
						}}
					>
						My Favorites
					</MenuItem>
					<MenuItem
						onClick={logoutHandler}
						mt={4}
						icon={<IoIosLogOut size={18} />}
						_hover={{
							bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
						}}
					>
						Logout
					</MenuItem>
				</MenuGroup>
			</MenuList>
		</Menu>
	);
};

export default ProfileOptions;
