import axios from 'axios';
import { ethers } from 'ethers';

interface L1Balance {
	wei: ethers.BigNumber;
	eth: string;
}
export const getL1Balance = async (address: string): Promise<L1Balance | null> => {
	let balances: L1Balance | null = null;
	const network = process.env.NEXT_NETWORK ?? 'sepolia';
	const options = process.env.NEXT_ALCHEMY_API ? { alchemy: process.env.NEXT_ALCHEMY_API } : {};
	const provider = ethers.getDefaultProvider(network, options);
	await provider.getBalance(address).then((balance) => {
		balances = {
			wei: balance,
			eth: ethers.utils.formatEther(balance),
		};
	});

	return balances;
};

export const getL1Address = async () => {
	const accounts = await (window as any).ethereum.request({
		method: 'eth_requestAccounts',
	});
	const provider = new ethers.providers.Web3Provider((window as any).ethereum);

	const signer = provider.getSigner(accounts[0]);
	const address = await signer.getAddress();
	if (!address) {
		throw new Error('Please activate MetaMask first.');
	}
	return address.toLowerCase();
};

export const signL1Message = async (message: string) => {
	const accounts = await (window as any).ethereum.request({
		method: 'eth_requestAccounts',
	});
	const provider = new ethers.providers.Web3Provider((window as any).ethereum);
	const signer = provider.getSigner(accounts[0]);

	//Metamask doesnt expect a password so leave blank
	return signer.signMessage(message);
};

export const convertEtherToUSD = async (balance: any) => {
	const ethPrice = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD');
	if (ethPrice.status == 200 && !isNaN(balance)) {
		return (Number.parseFloat(ethers.utils.formatEther(balance)) * Number.parseFloat(ethPrice.data.USD)).toFixed(2);
	}
};
