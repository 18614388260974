import { Web3Provider } from '@ethersproject/providers';
import { CONTRACT_ADDR, imageURL } from '@theark/react-common';
import axios from 'axios';
import { ESTUARY_API, getTokenHeader } from '@theark/react-common';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { firebaseApp } from '../common/firebase';
import { MarketPlaceRequestType } from './marketplace/types';
import { ArkState, ConfigKey, ILoginProps, ISiteConfig, Nullable, ProviderPreference, UploadResponse } from './types';
import { UserRequestType } from './user/types';
import imageCompression from 'browser-image-compression';
import * as gtag from '@theark/react-common';
import { magic } from '@theark/react-common';
import { generateWalletConnection, getStarkPublicKey, IMX_CLIENT } from '../utils';
import {
	AnyToken,
	GetSignableCancelOrderRequest,
	GetSignableTradeRequest,
	ListBalancesResponse,
	ListOrdersResponse,
	TokenAmount,
	UnsignedOrderRequest,
} from '@imtbl/core-sdk';
import { HistoryRequestType } from './history/types';
import { WalletActionType } from './wallet/actions';
import { parseEther } from 'ethers/lib/utils';

export enum ActionType {
	ShowDialogLoginRegister = 'ShowDialogLoginRegister',
	LoginRegister = 'LoginRegister',
	InitMagic = 'InitMagic',
	UserMetadata = 'UserMetadata',
	UpdateProfile = 'UpdateProfile',
	UpdateUserInfoWithToken = 'UpdateUserInfoWithToken',
	UpdateCategory = 'UpdateCategory',
	CreateCollections = 'CreateCollections',
	CollectionDetail = 'CollectionDetail',
	CollectionDetailError = 'CollectionDetailError',
	UpdateCollections = 'UpdateCollections',
	InRequest = 'InRequest',
	RemoveInRequest = 'RemoveInRequest',
	LogoutUser = 'LogoutUser',
	CreateCollectionError = 'CreateCollectionError',
	UpdateCollectionError = 'UpdateCollectionError',
	CollectionCategories = 'CollectionCategories',
	CollectionByCategory = 'CollectionByCategory',
	CollectionProfileById = 'CollectionProfileById',
	CollectionByUserId = 'CollectionByUserId',
	CreateNFT = 'CreateNFT',
	CreateApplication = 'CreateApplication',
	CreateApplicationError = 'CreateApplicationError',
	NftCollectionByCollectionId = 'NftCollectionByCollectionId',
	Landing = 'Landing',
	LoadingLanding = 'LoadingLanding',
	FetchCollectionOrders = 'FetchCollectionOrders',
	FetchCollectionOrdersSuccess = 'FetchCollectionOrdersSuccess',
	FetchCollectionOrdersError = 'FetchCollectionOrdersError',
}

export enum RequestType {
	LoginRegister = 'LoginRegister',
	UserSettings = 'UserSettings',
	CreateCollections = 'CreateCollections',
	CreateCollectionError = 'CreateCollectionError',
	UpdateCollections = 'UpdateCollections',
	UpdateCollectionError = 'UpdateCollectionError',
	CreateNft = 'CreateNft',
	CreateApplication = 'CreateApplication',
	CreateApplicationError = 'CreateApplicationError',
	CollectionByCategory = 'CollectionByCategory',
	NftCollectionByCollectionId = 'NftCollectionByCollectionId',
}

export interface FetchCollectionOrders {
	type: ActionType.FetchCollectionOrders;
}

export interface FetchCollectionOrdersSuccess {
	type: ActionType.FetchCollectionOrdersSuccess;
	payload: ListOrdersResponse;
}

export interface FetchCollectionOrdersError {
	type: ActionType.FetchCollectionOrdersError;
	payload: string;
}

export interface Landing {
	type: ActionType.Landing;
	payload: any;
}

export interface LoadingLanding {
	type: ActionType.LoadingLanding;
	payload: boolean;
}

export interface InRequest {
	type: ActionType.InRequest;
	payload: string;
}

export interface RemoveInRequest {
	type: ActionType.RemoveInRequest;
	payload: string;
}

export interface NftCollectionByCollectionId {
	type: ActionType.NftCollectionByCollectionId;
	payload: any;
}

export interface CollectionByUserId {
	type: ActionType.CollectionByUserId;
	payload: any;
}

export interface CollectionProfileById {
	type: ActionType.CollectionProfileById;
	payload: any;
}

export interface CollectionByCategory {
	type: ActionType.CollectionByCategory;
	payload: any;
}

export interface CollectionCategories {
	type: ActionType.CollectionCategories;
	payload: any;
}

export interface LogoutUser {
	type: ActionType.LogoutUser;
}

export interface ShowDialogLoginRegister {
	type: ActionType.ShowDialogLoginRegister;
	payload: boolean;
}

export interface LoginRegister {
	type: ActionType.LoginRegister;
	payload: any;
}

export interface InitMagic {
	type: ActionType.InitMagic;
	payload: any;
}

export interface UserMetadata {
	type: ActionType.UserMetadata;
	payload: any;
}

export interface UpdateProfile {
	type: ActionType.UpdateProfile;
	payload: any;
}

export interface CreateCollections {
	type: ActionType.CreateCollections;
	payload: any;
}

export interface UpdateUserInfoWithToken {
	type: ActionType.UpdateUserInfoWithToken;
	payload: any;
}

export interface UpdateCategory {
	type: ActionType.UpdateCategory;
	payload: any;
}

export interface CollectionDetail {
	type: ActionType.CollectionDetail;
	payload: any;
}
export interface CollectionDetailError {
	type: ActionType.CollectionDetailError;
	payload: boolean;
}
export interface UpdateCollections {
	type: ActionType.UpdateCollections;
	payload: any;
}

export interface CreateCollectionError {
	type: ActionType.CreateCollectionError;
	payload: boolean;
}
export interface UpdateCollectionError {
	type: ActionType.UpdateCollectionError;
	payload: any;
}
export interface CreateApplication {
	type: ActionType.CreateApplication;
	payload: any;
}
export interface CreateApplicationError {
	type: ActionType.CreateApplicationError;
	payload: boolean;
}

export type ArkActions =
	| CreateNFT
	| CollectionByUserId
	| CreateApplication
	| CreateApplicationError
	| CollectionProfileById
	| CollectionByCategory
	| CollectionCategories
	| ShowDialogLoginRegister
	| LoginRegister
	| InitMagic
	| UserMetadata
	| UpdateProfile
	| UpdateUserInfoWithToken
	| InRequest
	| RemoveInRequest
	| LogoutUser
	| UpdateCategory
	| CreateCollections
	| CollectionDetail
	| CollectionDetailError
	| UpdateCollections
	| CreateCollectionError
	| UpdateCollectionError
	| NftCollectionByCollectionId
	| Landing
	| LoadingLanding
	| FetchCollectionOrders
	| FetchCollectionOrdersSuccess
	| FetchCollectionOrdersError;

export const fetchCollectionOrders = (): FetchCollectionOrders => ({
	type: ActionType.FetchCollectionOrders,
});

export const fetchCollectionOrdersSuccess = (orders: ListOrdersResponse): FetchCollectionOrdersSuccess => ({
	type: ActionType.FetchCollectionOrdersSuccess,
	payload: orders,
});

export const fetchCollectionOrdersError = (error: string): FetchCollectionOrdersError => ({
	type: ActionType.FetchCollectionOrdersError,
	payload: error,
});

// Add the function to handle fetching orders as an action
export const handleFetchCollectionOrders = (collectionName: string, dispatch: any) => {
	dispatch(fetchCollectionOrders());
	getCollectionOrders(collectionName, dispatch);
};

export const collectionCategories = (payload: any): CollectionCategories => ({
	type: ActionType.CollectionCategories,
	payload,
});

export const showDialogLoginRegister = (flag: boolean): ShowDialogLoginRegister => ({
	type: ActionType.ShowDialogLoginRegister,
	payload: flag,
});

export const loginRegister = (payload: any): LoginRegister => ({
	type: ActionType.LoginRegister,
	payload: payload,
});

export const userMetadata = (payload: any): UserMetadata => ({
	type: ActionType.UserMetadata,
	payload: payload,
});

export const updateUserProfile = (payload: any): UpdateProfile => ({
	type: ActionType.UpdateProfile,
	payload: payload,
});

export const createUserCollection = (payload: any): CreateCollections => ({
	type: ActionType.CreateCollections,
	payload: payload,
});

export const createApplication = (payload: any): CreateApplication => ({
	type: ActionType.CreateApplication,
	payload: payload,
});

export const createApplicationError = (payload: boolean): CreateApplicationError => ({
	type: ActionType.CreateApplicationError,
	payload: payload,
});

export const updateUserCollection = (payload: any): UpdateCollections => ({
	type: ActionType.UpdateCollections,
	payload: payload,
});
export const updateUserInfoWithToken = (payload: any): UpdateUserInfoWithToken => {
	return {
		type: ActionType.UpdateUserInfoWithToken,
		payload: payload,
	};
};

export const updateCategory = (payload: any): UpdateCategory => {
	return {
		type: ActionType.UpdateCategory,
		payload: payload,
	};
};

export const getCollectionDetail = (payload: any): CollectionDetail => {
	return {
		type: ActionType.CollectionDetail,
		payload: payload,
	};
};

export const getCollectionError = (error: boolean): CollectionDetailError => {
	return {
		type: ActionType.CollectionDetailError,
		payload: error,
	};
};

export const createCollectionError = (error: boolean): CreateCollectionError => {
	return {
		type: ActionType.CreateCollectionError,
		payload: error,
	};
};

export const updateCollectionSuccess = (payload: any): UpdateCollections => {
	return {
		type: ActionType.UpdateCollections,
		payload: payload,
	};
};

export const updateCollectionError = (error: any): UpdateCollectionError => {
	return {
		type: ActionType.UpdateCollectionError,
		payload: error,
	};
};

export const requestInProcess = (
	payload: RequestType | MarketPlaceRequestType | UserRequestType | HistoryRequestType | WalletActionType
): InRequest => {
	return {
		type: ActionType.InRequest,
		payload,
	};
};

export const removeRequestInProcess = (
	payload: RequestType | MarketPlaceRequestType | UserRequestType | HistoryRequestType | WalletActionType
): RemoveInRequest => {
	return {
		type: ActionType.RemoveInRequest,
		payload,
	};
};

export const logoutUser = (): LogoutUser => {
	return {
		type: ActionType.LogoutUser,
	};
};

export const fetchLandingPageDetails = (payload: any): Landing => {
	return {
		type: ActionType.Landing,
		payload,
	};
};

export const loadingLandingPageDetails = (payload: boolean): LoadingLanding => {
	return {
		type: ActionType.LoadingLanding,
		payload,
	};
};

const supportedProfileFields: Array<string> = [
	'firstName',
	'lastName',
	'email',
	'mobile',
	'city',
	'country',
	'username',
	'description',
	'avatar',
	'cover',
	'browser_key',
];
const supportedApplicationFields: Array<string> = [
	'firstName',
	'lastName',
	'mobile',
	'email',
	'city',
	'country',
	'username',
	'description',
	'avatar',
	'cover',
	'applicationDescription',
	'sampleWork',
];

const supportedCollectionFields: Array<string> = [
	'name',
	'description',
	'category',
	'icon',
	'cover',
	'website',
	'instagram',
	'tiktok',
	'twitter',
	'userID',
	'discord',
];

export const getNftCollectionByCollectionId = (payload: any): NftCollectionByCollectionId => ({
	type: ActionType.NftCollectionByCollectionId,
	payload,
});

export const fetchNftCollectionByCollectionId = async (dispatch: any, collectionId: string, keyword: string, tokenId: string) => {
	try {
		dispatch(requestInProcess(RequestType.NftCollectionByCollectionId));
		dispatch(
			getNftCollectionByCollectionId({
				data: [],
				count: 0,
			})
		);
		const res: any = await axios(`/api/nft/collection?collectionID=${collectionId}&keyword=${keyword}&tokenID=${tokenId}`, {
			method: 'GET',
		});
		if (res?.data?.success) {
			dispatch(
				getNftCollectionByCollectionId({
					data: res?.data?.metadata,
					count: res?.data?.count,
				})
			);
		}
	} catch (e) {
		console.log('Unable to fetch user Info.');
	}
	dispatch(removeRequestInProcess(RequestType.NftCollectionByCollectionId));
};

export const getUserInfoWithToken = async (tokenHeader: string, dispatch: any) => {
	try {
		const res: any = await axios('/api/user', {
			method: 'GET',
			headers: getTokenHeader(tokenHeader),
		});
		if (res?.data?.success) {
			dispatch(
				updateUserInfoWithToken({
					data: res?.data?.metadata,
					token: tokenHeader,
				})
			);
		}
	} catch (e) {
		console.log('Unable to fetch user Info.');
	}
};

export const notificationPermission = async (userInfo: any, state: ArkState, toast: any, dispatch: any) => {
	if (await isSupported()) {
		toast({
			title: 'Notification!',
			description: 'Please Allow Permission to Send Notifications to Get Latest Information on the Collections',
			status: 'info',
			duration: 5000,
			isClosable: true,
		});
		try {
			Notification.requestPermission()
				.then(async (result) => {
					console.log('status', result);
					if (result == 'granted') {
						// export const firebaseAnalytics = getAnalytics(firebaseApp);
						const messages = getMessaging(firebaseApp);
						getToken(messages, { vapidKey: process.env.FIREBASE_VAPID_KEY })
							.then(async (currentToken: string | undefined) => {
								const formData = new FormData();
								if (currentToken && formData) {
									userInfo.browser_key = currentToken;
									supportedProfileFields.forEach((key: string) => {
										if (userInfo[key]) {
											formData.append(key, userInfo[key]);
										}
									});
									try {
										const res = await axios('/api/update-profile', {
											method: 'PATCH',
											headers: getTokenHeader(state?.user?.accessToken || ''),
											data: formData,
										});
										if (res.data.success) {
											dispatch(
												updateUserProfile({
													success: true,
													error: false,
													data: res.data.metadata,
													message: '',
												})
											);
											toast({
												title: 'Notification Success!',
												description: 'You have successfully subscribed to the notifications!',
												status: 'success',
												duration: 5000,
												isClosable: true,
											});
										}
									} catch (e) {
										toast({
											title: 'Profile Error!',
											description: 'Unable to update user data',
											status: 'error',
											duration: 5000,
											isClosable: true,
										});
										dispatch(
											updateUserProfile({
												success: false,
												error: true,
												data: [],
												message: 'Unable to update user data',
											})
										);
									}
								} else {
									toast({
										title: 'Notification Error!',
										description: 'No registration token available. Request permission to generate one.',
										status: 'error',
										duration: 5000,
										isClosable: true,
									});
									console.log('No registration token available. Request permission to generate one.');
								}
							})
							.catch((err) => {
								toast({
									title: 'Notification Error!',
									description: 'An error occurred while retrieving token. ' + err,
									status: 'error',
									duration: 5000,
									isClosable: true,
								});
								console.log('An error occurred while retrieving token. ', err);
							});
					}
				})
				.catch((reason: any) => {
					toast({
						title: 'Notification Error!',
						description: 'Notification not supported' + reason,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
					console.log('Notification not supported', reason);
				});
		} catch (e) {
			toast({
				title: 'Notification Error!',
				description: 'Unable to fetch notification permission.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
			toast('Unable to fetch notification permission.');
		}
	}
};

export const updateCreateCollectionError = async (error: boolean, dispatch: any) => {
	dispatch(createCollectionError(false));
};
export const updateCreateApplicationError = async (error: boolean, dispatch: any) => {
	dispatch(createApplicationError(false));
};
export const updateEditCollectionError = async (error: boolean, dispatch: any) => {
	dispatch(updateCollectionError({ data: false }));
};

export const updateProfile = async (profile: any, state: ArkState, dispatch: any) => {
	const formData = new FormData();
	supportedProfileFields.forEach((key: string) => {
		if (profile[key]) {
			formData.append(key, profile[key]);
		}
	});

	try {
		const res = await axios('/api/update-profile', {
			method: 'PATCH',
			headers: getTokenHeader(state?.user?.accessToken || ''),
			data: formData,
		});
		dispatch(
			updateUserProfile({
				success: true,
				error: false,
				data: res?.data?.metadata,
				message: '',
			})
		);
		return { success: true, data: res?.data?.metadata };
	} catch (e) {
		dispatch(
			updateUserProfile({
				success: false,
				error: true,
				data: [],
				message: 'Unable to update user data',
			})
		);
		return { success: false, error: e instanceof Error ? e.message : 'Unknown error occurred' };
	}
};

export const createUserApplication = async (application: any, state: ArkState, dispatch: any) => {
	const formData = new FormData();
	supportedApplicationFields.forEach((key: string) => {
		formData.append(key, application[key]);
	});
	formData.append('userID', state?.userInfo?.ID ? state?.userInfo?.ID.toString() : '');

	try {
		const res: any = await axios('/api/create-application', {
			method: 'POST',
			headers: getTokenHeader(state?.user?.accessToken || ''),
			data: formData,
		});
		if (res?.data?.success) {
			dispatch(
				createApplication({
					success: true,
					error: false,
					data: res?.data?.metadata,
					message: '',
				})
			);
		}
	} catch (e) {
		dispatch(createApplicationError(false));
	}
};

export const createCollection = async (collectionInfo: any, state: ArkState, dispatch: any) => {
	const formData = new FormData();
	supportedCollectionFields.forEach((key: string) => {
		formData.append(key, collectionInfo[key]);
	});

	formData.append('userID', state?.userInfo?.ID ? state?.userInfo?.ID.toString() : '');
	try {
		const res: any = await axios('/api/create-collection', {
			method: 'POST',
			headers: getTokenHeader(state?.user?.accessToken || ''),
			data: formData,
		});
		if (res?.data?.success) {
			dispatch(
				createUserCollection({
					success: true,
					error: false,
					data: res?.data?.metadata,
					message: '',
				})
			);
		}
	} catch (e) {
		dispatch(createCollectionError(true));
	}
};

export const updateCollection = async (collectionInfo: any, collectionID: any, state: ArkState) => {
	let updated = false;
	const formData = new FormData();
	supportedCollectionFields.forEach((key: string) => {
		formData.append(key, collectionInfo[key]);
	});

	if (!formData.has('userID')) {
		formData.append('userID', state?.userInfo?.ID ? state?.userInfo?.ID.toString() : '');
	}
	formData.append('id', collectionID);
	try {
		await axios('/api/update-collection', {
			method: 'PUT',
			headers: getTokenHeader(state?.user?.accessToken || ''),
			data: formData,
		});
		updated = true;
	} catch (e) {
		updated = false;
	}
	return updated;
};

export const loginRequest = async (props: ILoginProps) => {
	const { dispatch, request, type, toast } = props;

	const notLoggedIn = () => {
		dispatch(
			loginRegister({
				isLogged: false,
				token: null,
			})
		);
	};

	const headers = {
		'Content-Type': 'application/json',
		Authorization: '',
	};

	let postData = {};
	const dispatchData = {};

	if (request && type) {
		let walletAddress: string | null;

		// Validate Magic Token
		if (request.email && magic && type === ProviderPreference.MAGIC_LINK) {
			try {
				const email = request.email;
				const didToken = await magic.auth.loginWithMagicLink({ email, showUI: true });
				await axios('/api/validate', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + didToken,
					},
				});
				const userMetadata = await magic.user.getMetadata();
				walletAddress = userMetadata.publicAddress;
			} catch (e: any) {
				console.log('Error ', e);
				notLoggedIn();
				dispatch(removeRequestInProcess(RequestType.LoginRegister));
				toast({
					title: 'Login Error!',
					description: e.toString(),
					status: 'error',
					variant: 'arkError',
					position: 'bottom-right',
				});
				throw Error;
			}
		} else if (type === ProviderPreference.METAMASK && request.walletAddress) {
			walletAddress = request.walletAddress;
		} else {
			toast({
				title: 'Error: No valid Login Params Found!',
				status: 'error',
				variant: 'arkError',
				position: 'bottom-right',
			});
			console.log('Error: No valid Login Params Found!');
			notLoggedIn();
			dispatch(removeRequestInProcess(RequestType.LoginRegister));
			return;
		}

		if (!walletAddress || !request.l1Signer || !request.l1Provider) {
			console.log('Error: No Wallet Address Found!');
			toast({
				title: 'Error: No Wallet Address Found!',
				status: 'error',
				variant: 'arkError',
				position: 'bottom-right',
			});
			notLoggedIn();
			dispatch(removeRequestInProcess(RequestType.LoginRegister));
			return;
		}

		let res: any;
		res = await axios.get('/api/users', {
			params: { walletAddress },
		});
		if (res.data.metadata.length === 0) {
			await axios.post('/api/register', {
				walletAddress,
				email: request.email ?? '',
			});
			res = await axios.get('/api/users', {
				params: { walletAddress },
			});
		}

		const nonce = res.data.metadata[0].NONCE;
		let signature: string;
		try {
			signature = await request.l1Signer.signMessage(nonce);
		} catch {
			toast({
				title: 'Error: MetaMask Message Signature: User denied message signature.',
				status: 'error',
				variant: 'arkError',
				position: 'bottom-right',
			});
			notLoggedIn();
			dispatch(removeRequestInProcess(RequestType.LoginRegister));
			return;
		}

		postData = JSON.stringify({
			walletAddress: walletAddress,
			signature: signature,
		});

		try {
			// Register user on IMX
			await getStarkPublicKey(walletAddress, request.l1Provider as Web3Provider);

			// Continue with Login
			const loginResponse = await axios('/api/login', {
				method: 'POST',
				headers: headers,
				data: postData,
			});

			dispatch(loginRegister(dispatchData));
			dispatch(
				userMetadata({
					accessToken: loginResponse.data?.accessToken,
					expiry: loginResponse.data?.expiry,
					userInfo: loginResponse.data?.metadata,
				})
			);
		} catch (e: any) {
			toast({
				title: 'Login Error!',
				description: e.toString(),
				status: 'error',
				variant: 'arkError',
				position: 'bottom-right',
			});
			console.log('Error ', e);
			notLoggedIn();
		} finally {
			dispatch(removeRequestInProcess(RequestType.LoginRegister));
		}
	}
};

export const logoutRequest = async (tokenHeader: any, dispatch: any) => {
	try {
		const res: any = await axios('/api/logout', {
			method: 'GET',
			headers: getTokenHeader(tokenHeader),
		});

		if (res.data.success) {
			dispatch(logoutUser());
		}
	} catch (e) {
		console.log('logout error', e);
	}
};

export const getCategory = async (tokenHeader: any, dispatch: any) => {
	try {
		const res: any = await axios('/api/category', {
			method: 'GET',
			headers: getTokenHeader(tokenHeader),
		});
		if (res?.data?.success) {
			dispatch(
				updateCategory({
					data: res?.data?.metadata,
				})
			);
		}
	} catch (e) {
		console.log(e);
		console.log('Unable to fetch user Info.');
	}
};

export const getSingleCollection = async (tokenHeader: any, id: any, dispatch: any) => {
	try {
		const res: any = await axios('/api/collection-detail?id=' + id, {
			method: 'GET',
			headers: getTokenHeader(tokenHeader),
		});
		if (res?.data?.success) {
			dispatch(
				getCollectionDetail({
					data: res?.data?.metadata,
				})
			);
		}
	} catch (e) {
		dispatch(getCollectionError(true));
		console.log('Unable to fetch collection info.');
	}
};

export const getCollectionOrders = (collectionName: string, dispatch: any) => {
	const ordersApi = IMX_CLIENT.ordersApi;
	const metdataFilter = {
		collection: [collectionName],
	};
	let orders: ListOrdersResponse = {
		cursor: '',
		remaining: 0,
		result: [],
	};

	ordersApi
		.listOrdersV3({
			status: 'active',
			...(Object.keys(metdataFilter).length > 0 && { sellMetadata: JSON.stringify(metdataFilter) }),
			includeFees: true,
			sellTokenAddress: CONTRACT_ADDR,
		})
		.then((response) => {
			orders = response.data;
			dispatch(fetchCollectionOrdersSuccess(orders));
		})
		.catch((error) => {
			dispatch(fetchCollectionOrdersError(error.toString()));
		});
};

export const initMagic = (player: any): InitMagic => ({
	type: ActionType.InitMagic,
	payload: player,
});

export const fetchCollectionCategories = async (dispatch: any) => {
	const headers = {
		'Content-Type': 'application/json',
	};

	try {
		const res: any = await axios('/api/category', {
			method: 'GET',
			headers,
		});

		if (res.data.success) {
			dispatch(collectionCategories(res?.data?.metadata));
		}
	} catch (e) {
		console.log('logout error', e);
		dispatch(collectionCategories([]));
	}
};

export const collectionByCategory = (payload: any) => ({
	type: ActionType.CollectionByCategory,
	payload,
});

export const fetchCollectionByCategory = async (dispatch: any, categoryID: number, keyword: any, currentPage: number, perPage: number) => {
	dispatch(
		collectionByCategory({
			metadata: [],
			count: 0,
		})
	);
	dispatch(requestInProcess(RequestType.CollectionByCategory));
	const headers = {
		'Content-Type': 'application/json',
	};
	try {
		const res: any = await axios(
			`/api/collection/category?categoryID=${categoryID}&keyword=${keyword}&page=${currentPage}&perPage=${perPage}`,
			{
				method: 'GET',
				headers,
			}
		);
		if (res.data.success) {
			dispatch(collectionByCategory(res?.data));
		}
	} catch (e) {
		console.log('logout error', e);
	}
	dispatch(removeRequestInProcess(RequestType.CollectionByCategory));
};

export const collectionProfileById = (payload: any) => ({
	type: ActionType.CollectionProfileById,
	payload,
});

export const fetchCollectionProfileById = async (dispatch: any, id: any) => {
	const headers = {
		'Content-Type': 'application/json',
	};
	try {
		const res: any = await axios('/api/collection/profile?id=' + id, {
			method: 'GET',
			headers,
		});
		if (res.data.success) {
			dispatch(collectionProfileById(res?.data?.metadata));
		}
	} catch (e) {
		console.log('logout error', e);
	}
};

export const collectionByUserId = (payload: any) => ({
	type: ActionType.CollectionByUserId,
	payload,
});

export const fetchCollectionByUserId = async (dispatch: any, userId: any) => {
	const headers = {
		'Content-Type': 'application/json',
	};
	try {
		const res: any = await axios('/api/collection/user?userId=' + userId, {
			method: 'GET',
			headers,
		});
		if (res.data.success) {
			dispatch(collectionByUserId(res?.data?.metadata));
		}
	} catch (e) {
		console.log('logout error', e);
	}
};

export interface CreateNFT {
	type: ActionType.CreateNFT;
	payload: any;
}

export const createNft = (payload: any) => ({
	type: ActionType.CreateNFT,
	payload,
});

export const requestCreateNFT = async (dispatch: any, data: any, state: ArkState) => {
	const formData = new FormData();
	Object.keys(data).forEach((key: string) => {
		if (typeof data[key] === 'object' && Object.keys(data[key]).length > 0) {
			Object.keys(data[key]).forEach((subKey: string) => {
				formData.append(`${key}.${subKey}`, data[key][subKey]);
			});
		} else {
			formData.append(key, data[key]);
		}
	});
	if (!data.dropNFT) {
		formData.append('dropNFT', '0');
	}

	try {
		const res = await axios('/api/nft/create', {
			method: 'POST',
			headers: getTokenHeader(state?.user?.accessToken || ''),
			data: formData,
		});
		if (res.data.success) {
			gtag.event({
				action: 'mint',
				category: 'NFT',
				label: 'Mint NFT Success',
				value: '1',
			});
			dispatch(
				createNft({
					data: res?.data?.metadata,
					error: null,
				})
			);
		}
	} catch (e) {
		console.log('logout error', e);
		dispatch(createNft({ data: {}, error: e }));
	}
};

export const requestUploadToken = async (token?: string): Promise<string> => {
	return `abcdef_${token}`; // dummy token
};

export const requestUploadMedia = async (data: string | Blob, token: string): Promise<UploadResponse> => {
	console.log('token', token);
	const formData = new FormData();
	formData.append('data', data);

	const type = (data as File).type;
	const isVideo: boolean = type?.indexOf('video') !== -1 ? true : false;

	try {
		console.log(ESTUARY_API, 'ESTUARY_API');
		const res = await axios(`/api/upload/add`, {
			method: 'POST',
			data: formData,
		});

		if (!isVideo) {
			const options = {
				alwaysKeepResolution: true,
				maxSizeMB: 1,
				useWebWorker: true,
			};
			const compressedResult = await imageCompression(data as File, options);
			const formData = new FormData();
			formData.append('data', compressedResult);

			const compressedRes = await axios(`/api/upload/add`, {
				method: 'POST',
				data: formData,
			});

			pingIpfsGateway(imageURL(compressedRes.data.cid));
			return {
				download_url: res.data.cid,
				image_url: compressedRes.data.cid,
			};
		} else {
			pingIpfsGateway(imageURL(res.data.cid));
			return {
				animation_url: res.data.cid,
				animation_url_mime_type: type,
			};
		}
	} catch (error) {
		console.log('upload error', error);
	}
	return {};
};

// Config Actions
export enum ConfigType {
	SiteConfig,
}

export interface SiteConfig {
	type: ConfigType.SiteConfig;
	payload: any;
}

export type ConfigActions = SiteConfig;

export const siteConfig = (payload: any) => ({
	type: ConfigType.SiteConfig,
	payload,
});

export const fetchSiteConfig = async (dispatch: any) => {
	const headers = {
		'Content-Type': 'application/json',
	};
	try {
		const res = await axios('/api/config', {
			method: 'GET',
			headers,
		});
		if (res.data.success) {
			dispatch(siteConfig(res?.data?.metadata));
		}
	} catch (e) {
		console.log('logout error', e);
	}
};

export const getSiteConfigByKey = (siteConfig: ISiteConfig[], key: ConfigKey): Nullable<ISiteConfig> => {
	return siteConfig.filter((item) => item.NAME === key)[0];
};

export const buyNft = async (orderId: string, address: string, provider: Web3Provider) => {
	const walletConnection = await generateWalletConnection(provider);
	const tradeRequest: GetSignableTradeRequest = {
		order_id: Number.parseInt(orderId),
		user: address,
	};

	try {
		return await IMX_CLIENT.createTrade(walletConnection, tradeRequest);
	} catch (e: any) {
		console.log('Error orderId ', e);
	}
};

// sell NFT
export const sellNft = async (price: number, tokenId: string, tokenAddress: string, provider: Web3Provider) => {
	try {
		const walletConnection = await generateWalletConnection(provider);
		const orderParameters = {
			// Fee-exclusive amount to buy the asset
			// Change '0.1' to any value of the currency wanted to sell this asset
			amount_buy: parseEther(price.toString()).toString(),

			// Amount to sell (quantity)
			// Change '1' to any value indicating the number of assets you are selling
			amount_sell: '1',

			// expiration_timestamp: timestamp,

			buy: {
				amount: parseEther(price.toString()).toString(),
				type: 'ETH',
			},

			sell: {
				amount: '1',
				tokenAddress,
				tokenId,
				type: 'ERC721',
			},
		} as UnsignedOrderRequest;

		const response = await IMX_CLIENT.createOrder(walletConnection, orderParameters);
		console.log('End: Create Order Completed');
		return response;
	} catch (e) {
		console.log('Error orderId ', e);
	}
};

// cancel Sell NFT
export const cancelSellNft = async (orderId: string, provider: Web3Provider) => {
	const walletConnection = await generateWalletConnection(provider);
	const requestParams: GetSignableCancelOrderRequest = {
		order_id: Number(orderId), // order ID here
	};

	try {
		const response = await IMX_CLIENT.cancelOrder(walletConnection, requestParams);
		return response;
	} catch (e: any) {
		console.log('Error orderId ', e);
	}
};

// prepare an NFT withdrawal
export const prepareWithdrawalNFT = async (nftID: string, provider: Web3Provider) => {
	const walletConnection = await generateWalletConnection(provider);
	const withdrawRequest: TokenAmount = {
		type: 'ERC721',
		tokenId: nftID,
		tokenAddress: CONTRACT_ADDR ?? '',
	};

	const response = await IMX_CLIENT.prepareWithdrawal(walletConnection, withdrawRequest)
		.then((res) => {
			console.log('Withdraw NFT successfully added to queue.', res);
			return res;
		})
		.catch((err) => {
			console.log('Error prepareWithdrawalNFT ', err);
		});
	return response;
};

export const completeWithdrawalNFT = async (nftID: string, _tokenAddress: string, provider: Web3Provider) => {
	const l1Signer = provider.getSigner();
	const starkPublicKey = await getStarkPublicKey(await l1Signer.getAddress(), provider);
	const withdrawRequest: AnyToken = {
		type: 'ERC721',
		tokenId: nftID,
		tokenAddress: CONTRACT_ADDR ?? '',
	};

	const response = await IMX_CLIENT.completeWithdrawal(l1Signer, starkPublicKey, withdrawRequest)
		.then((res) => {
			console.log('Withdraw NFT successfully completed.', res);
			return res;
		})
		.catch((err) => {
			console.log('Error completeWithdrawalNFT ', err);
		});
	return response;
};

export const getWalletBalance = async (address: string): Promise<ListBalancesResponse> => {
	let response: ListBalancesResponse = { result: [], cursor: '', remaining: 0 };
	try {
		const balancesApi = IMX_CLIENT.balanceApi;
		response = (await balancesApi.listBalances({ owner: address })).data;
	} catch (e: any) {
		console.log('Error getWalletBalance ', e);
	}
	return response;
};

export const fetchLandingPage = async (state: ArkState, dispatch: any) => {
	dispatch(loadingLandingPageDetails(true));
	try {
		const res = await axios.get('/api/landing');

		if (res.data.success) {
			dispatch(
				fetchLandingPageDetails({
					success: true,
					error: false,
					message: '',
					data: res?.data.metadata,
				})
			);
		}
	} catch (e: any) {
		dispatch(
			fetchLandingPageDetails({
				success: false,
				error: true,
				message: e.response.data.metadata.message,
				data: [],
			})
		);
	}
};

const pingIpfsGateway = (url: string) => {
	axios.get(url);
};
