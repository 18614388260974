import { RequestType } from './actions';
import { ArkState } from './types';
import cloneDeep from 'lodash/cloneDeep';
import { MarketPlaceRequestType } from './marketplace/types';
import { UserRequestType } from './user/types';
import get from 'lodash/get';
import { ethers } from 'ethers';
import { CollectionState } from './collection/types';
import { HistoryRequestType } from './history/types';
import { WalletActionType } from './wallet/actions';

export const IUserMapper: any = {
	APPLICATION_FOR_CREATOR: 'applicationForCreator',
	BROWSER_KEY: 'browserKey',
	CITY: 'city',
	COUNTRY: 'country',
	EMAIL: 'email',
	ETH_ADDRESS: 'ethAddress',
	LAST_NAME: 'lastName',
	NAME: 'firstName',
	PHONE_NUMBER: 'mobile',
	USERNAME: 'username',
	USER_ID: 'userId',
	ID: 'id',
	USER_TYPE: 'userType',
	DESCRIPTION: 'description',
	COVER: 'cover',
	AVATAR: 'avatar',
};

const mapUserFields = (userInfo: any) => {
	const _temp: any = {};
	for (const key in IUserMapper) {
		const mapperKey: string = IUserMapper[key];
		if (userInfo.hasOwnProperty(key)) {
			_temp[mapperKey] = userInfo[key];
		}
	}
	return _temp;
};

export const ICollectionMapper: any = {
	NAME: 'name',
	DESCRIPTION: 'description',
	CATEGORY: 'category',
	ICON: 'icon',
	COVER: 'cover',
	WEBSITE: 'website',
	INSTAGRAM: 'instagram',
	TIKTOK: 'tiktok',
	TWITTER: 'twitter',
	USER_ID: 'userID',
	DISCORD: 'discord',
};

const mapCollectionFields = (collectionInfo: any) => {
	const _temp: any = {};
	for (const key in ICollectionMapper) {
		const mapperKey: string = ICollectionMapper[key];
		if (collectionInfo.hasOwnProperty(key)) {
			_temp[mapperKey] = collectionInfo[key];
		}
	}
	return _temp;
};

export const INftMapper: any = {
	imageUrl: 'image_url',
	name: 'name',
	description: 'description',
	collectionID: 'collectionID',
	creator: 'creator',
	tags: 'tags',
	dropNFT: 'dropNFT',
	properties: 'properties',
	royalties: 'royalties',
};

export const selectNFT = (state: ArkState, collectionId?: string) =>
	state && state.nft ? (collectionId ? { ...state.nft, collectionID: collectionId } : state.nft) : {};
export const selectCreateNFT = (state: ArkState) => state?.createNFT || {};
export const selectUserInfo = (state: ArkState) => mapUserFields(state?.userInfo || {});
export const selectCollectionByUserId = (state: ArkState) => state?.collectionByUserId || [];
export const selectCollectionInfo = (state: ArkState) => mapCollectionFields(state?.collectionInfo || {});
export const selectCollectionNotification = (state: CollectionState) => state?.createNotification || {};
export const selectInRequest = (
	state: ArkState,
	request: RequestType | MarketPlaceRequestType | WalletActionType | UserRequestType | HistoryRequestType
) => state.inRequest.indexOf(request) !== -1;
export const selectInRequestArray = (state: ArkState, request: RequestType[]) => {
	const found = request.some((r) => state.inRequest.indexOf(r) >= 0);
	return found;
};

export const selectCategories = (state: ArkState) => {
	const _category: Array<any> = Array.isArray(state.categories) ? cloneDeep(state.categories) : [];
	_category.unshift({
		description: 'Display All',
		id: 0,
		name: 'All',
		status: 'active',
	});
	return _category;
};

export const selectCollectionByCategory = (state: ArkState) => ({
	collectionByCategory: state.collectionByCategory,
	totalItems: state.collectionByCategoryCount,
});

export const selectCollectionProfile = (state: ArkState) => state.collectionProfile || {};

export const selectNftCollection = (state: ArkState) =>
	state.nftCollection || {
		data: [],
		count: 0,
	};

export const getNFTLandingPage = (state: ArkState) => {
	let orders: Array<any> = [];
	if (Array.isArray(state.landingPage?.orders)) {
		state.landingPage?.orders?.forEach((element: any) => {
			orders.push({
				categoryID: get(element, 'order_id', '0'),
				id: get(element, 'sell.data.id', '0'),
				name: get(element, 'sell.data.properties.name', ''),
				cover: get(element, 'sell.data.properties.image_url', ''),
				icon: get(element, 'sell.data.properties.collection.icon_url', ''),
				userID: get(element, 'user', ''),
				amount: ethers.utils.formatEther(get(element, 'buy.data.quantity_with_fees', 0)) + ' ETH',
				rawAmount: get(element, 'buy.data.quantity_with_fees', 0),
				tokenId: get(element, 'sell.data.token_id', ''),
				description: '',
			});
		});
	}
	return orders;
};
